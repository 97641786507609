import React from "react"
import { Field, reduxForm } from "redux-form"
import { connect } from "react-redux"
import Section from "../../templates/Section"
import { crmContactPOST } from "../../state/actions"
import "../../styles/contact-form.scss"

class ContactForm extends React.Component {
  renderError({ error, touched }) {
    if (touched && error) {
      return <div className="contact-form__error">{error}</div>
    }
    return null
  }

  renderInput = ({ input, label, meta }) => (
    <div
      className={`contact-form__field${
        meta.touched && meta.error ? " --field-error" : ""
      }`}
    >
      <label className="contact-form__label" htmlFor="name">
        <span>{label}</span>
        <input
          {...input}
          className="contact-form__text"
          type="text"
          autoComplete="off"
        />
        {this.renderError(meta)}
      </label>
    </div>
  )

  renderTextarea = ({ input, label, meta }) => (
    <div
      className={`contact-form__field${
        meta.touched && meta.error ? " --field-error" : ""
      }`}
    >
      <label className="contact-form__label" htmlFor="name">
        <span>{label}</span>
        <textarea
          {...input}
          className="contact-form__textarea"
          autoComplete="off"
        />
        {this.renderError(meta)}
      </label>
    </div>
  )

  onSubmitHandler = formProps => {
    this.props.crmContactPOST(formProps)
  }

  render = () => {
    const { handleSubmit } = this.props

    return (
      <Section header="What's your goal?">
        <form
          onSubmit={handleSubmit(this.onSubmitHandler)}
          className="contact-form"
        >
          <Field
            name="name"
            component={this.renderInput}
            label="Name"
            required
          />
          <Field name="company" component={this.renderInput} label="Company" />
          <Field
            name="email"
            component={this.renderInput}
            label="Email"
            required
          />
          <Field name="phone" component={this.renderInput} label="Phone" />
          <Field name="website" component={this.renderInput} label="Website" />
          <Field
            name="goal"
            component={this.renderTextarea}
            label="Your business goal..."
            required
          />
          <div className="contact-form__buttons">
            <button id="contact-form__submit" className="btn-primary">
              Send
            </button>
          </div>
        </form>
      </Section>
    )
  }
}

const validate = formValues => {
  const errors = {}

  if (!formValues.name) {
    errors.name = "Please enter your full name..."
  }

  if (!formValues.email) {
    errors.email = "Please enter your email address..."
  }

  if ((formValues.goal && formValues.goal.length < 21) || !formValues.goal) {
    errors.goal =
      "Please write one or two sentences about what you want to achieve with a new website or marketing campaign..."
  }

  return errors
}

export default connect(null, { crmContactPOST })(
  reduxForm({ form: "contactForm", validate })(ContactForm)
)
